
const router_modular = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/HomeView')
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/AboutView.vue')
    },
    {
        path: '/about2',
        name: 'about2',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about2" */ '@/views/AboutView2.vue')
    },
    {
        path: '/about3',
        name: 'about3',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about3" */ '@/views/AboutView3.vue')
    },
    {
        path: '/about4',
        name: 'about4',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about4" */ '@/views/AboutView4.vue')
    },
    {
        path: '/about5',
        name: 'about5',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about5" */ '@/views/AboutView5.vue')
    },
    {
        path: '/about6',
        name: 'about6',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about6" */ '@/views/AboutView6.vue')
    },
    {
        path: '/about7',
        name: 'about7',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about7" */ '@/views/AboutView7.vue')
    },
    {
        path: '/result',
        name: 'result',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about8" */ '@/views/result.vue')
    },
    {
        path: '/sharePage',
        name: 'sharePage',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about9" */ '@/views/sharePage.vue')
    },
    // {
    //     path: '/leadsPage',
    //     name: 'leadsPage',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '@/views/leadsPage.vue')
    // }
]
export default router_modular