import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible/index.js';
import commonApi from './utils/common'
import Vant from 'vant';
import 'vant/lib/index.css';
import axios from 'axios'
import { Lazyload } from 'vant';
Vue.use(Vant);
Vue.config.productionTip = false
Vue.prototype.commonApi = commonApi;
Vue.use(Lazyload)

new Vue({
  axios,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
