import testApi from '@/api/test'
import store  from '@/store'
async function savePage(url) {
    let params = {
        channel:store.state.store_modular.channel,
        url:url,
    }
    
    let res = await testApi.postPage(params)
    
}

export default { savePage }