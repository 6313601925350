export default {
    /**接口请求的基础路径 */
    baseUrl: {
      dev: 'https://international-api.xinzhibang168.com', // 开发路径
      // dev: 'https://autumnfish.cn',
      // dev: 'http://10.0.0.147:8301',
      pro: 'https://international-api.xinzhibang168.com' // 生产路径
    },
  };
  

  