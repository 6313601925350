import store_modular from '@/store/store_modular/index.js'


import Vue from 'vue'
import Vuex from 'vuex'
// 引入插件
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const state = {};
const actions = {};
const getters = {}
const modules = {
    store_modular
}
const store = new Vuex.Store({
    state,
    actions,
    getters,
    modules,
    mutations: {
        resetState(state) {
            // 这里可以根据需要进行更复杂的操作，比如遍历对象并设置默认值等
          
            state.store_modular.name = '';
            state.store_modular.count = 63222;
            state.store_modular.chooseRes = ['A', 'A', 'A', 'A', 'A'];
            state.store_modular.curIndex = 0;
            state.store_modular.id = '';
        },
        $uStore(state, payload) {
            console.log(payload)
            // 判断是否多层级调用，state中为对象存在的情况，诸如user.info.score = 1
            let nameArr = payload.name.split('.');

            let len = nameArr.length;

            if (nameArr.length >= 2) {
                let obj = state[nameArr[0]];
                for (let i = 1; i < len - 1; i++) {
                    obj = obj[nameArr[i]];
                }
                console.log(obj, nameArr[len - 1], payload.value)
                obj[nameArr[len - 1]] = payload.value;

            } else {
                // 单层级变量，在state就是一个普通变量的情况
                state[payload.name] = payload.value;

            }
        }
    },
    /* vuex数据持久化配置 */
    plugins: [
        createPersistedState({
            // 存储方式：localStorage、sessionStorage、cookies  默认: localStorage
            // storage: window.sessionStorage,
            // 存储的 key 的key值
            key: "store",
            paths: ['store_modular']
            // render(state) {
            //   // 要存储的数据：本项目采用es6扩展运算符的方式存储了state中所有的数据
            //   return { ...state };
            // }
        })
    ]
});

export default store;