<template>
  <div id="app">
    
    <router-view />
    <audio src="./assets/Alibaba_Game_Music_Master_v3.mp3" loop :autoplay="true" ref="bgMic" hidden></audio>
    <audio src="./assets/Almonds.mp3" loop hidden ref="Almonds"></audio>
    <audio src="./assets/Cashews.mp3" loop hidden ref="Cashews"></audio>
    <audio src="./assets/Chestnuts.mp3" loop hidden ref="Chestnuts"></audio>
    <audio src="./assets/Pistachio.mp3" loop hidden ref="Pistachio"></audio>
    <audio src="./assets/Walnut.mp3" loop hidden ref="Walnut"></audio>
    <audio src="./assets/SFX_NutsGame_Click1.mp3" hidden ref="btn1"></audio>
    <audio src="./assets/SFX_NutsGame_Click2.mp3" hidden ref="btn2"></audio>
    <audio :src="pageSrc" ref="nextPageMap3" hidden ></audio>
    <audio src="./assets/SFX_NutsGame_ResultPage.mp3" hidden ref="Results"></audio>
  </div>
</template>
<script>
export default {
  data() {
    return {
      screenWidth: 0,
      screeHeight: 0,
      pageSrc:require('./assets/SFX_NutsGame_PageChange.mp3') 
    }
  },
  methods: {
    //音乐
    music() {
      var Audio = document.getElementsByTagName("audio")[0];
      // 兼容 ios系统 微信
      if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {

        Audio.play();

      } else {
        /*监听客户端抛事件 WeixinJSBridgeReady*/
        if (document.addEventListener) {

          document.addEventListener("WeixinJSBridgeReady", function () {

            Audio.play();
          }, false);
        } else if (document.attachEvent) {

          document.attachEvent("WeixinJSBridgeReady", function () {

            Audio.play();

          });

          document.attachEvent("onWeixinJSBridgeReady", function () {


            Audio.play();

          });

        }

      }

      // 兼容safari
      var voiceStatu = true;
      document.addEventListener("touchstart", function (e) {
        if (voiceStatu) {
          Audio.play();
          voiceStatu = false;
        }
      }, false);

    }
  },
  destroyed() {
    // this.$store.state.store_modular.musicRef.bgMic.pause()

  },
  // 以vue2为例
  mounted() {
    (this.screenWidth = document.documentElement.clientWidth),
      (this.screeHeight = document.documentElement.clientHeight),

      (window.onresize = () => {
        //屏幕尺寸变化就重新赋值
        return (() => {
          this.screenWidth = document.documentElement.clientWidth;
          this.screeHeight = document.documentElement.clientHeight;

        })();
      });
    this.music()

    this.$store.state.store_modular.musicRef = this.$refs
    this.$store.state.store_modular.musicRef.bgMic.play()
    //为pc则用iframe把移动端页面显示到页面中间
    let userAgentInfo = navigator.userAgent;
    let Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");
    let screenWidth = document.documentElement.clientWidth
    let screeHeight = document.documentElement.clientHeight
    this.screenWidth = screenWidth
    this.screeHeight = screeHeight


    //pc
    if (
      !Agents.some(item => userAgentInfo.toLowerCase().includes(item.toLowerCase())) &&
      !(self.frameElement && self.frameElement.tagName == "IFRAME") || screenWidth / screeHeight > 750 / 1418 && screenWidth > 500
    ) {
      let ifrTag = document.createElement("iframe");
      document.body.innerHTML = "";
      ifrTag.setAttribute("src", location.href);
      let styleObj = {
        width: "480px",
        height: 3096 * (480 / 1563) + 'px',
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
      };
      Object.entries(styleObj).forEach(([key, value]) => {
        ifrTag.style[key] = value;
      });
      document.body.append(ifrTag);
    }
  }

}
</script>
<style lang="less">
body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
/* structural elements 结构元素 */
dl,
dt,
dd,
ul,
ol,
li,
/* list elements 列表元素 */
pre,
/* text formatting elements 文本格式元素 */
fieldset,
lengend,
button,
input,
textarea,
/* form elements 表单元素 */
th,
td {
  /* table elements 表格元素 */
  margin: 0;
  padding: 0;
}

@font-face {

  font-family: 'Poppins';

  src: url('./assets/FontsFree-Net-Poppins-SemiBold.ttf') format('truetype');

}

#app {
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.wrap-ct {
  width: 100%;
  height: 100%;

  .home-ct {
    width: 100%;
    height: 100% !important;
  }
}

.gif-ct {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.van-cell {
  padding: 0.2rem !important;
}
</style>
